


.hero{
  background:url(../public/assets/big_hero_2.jpg); 
}
.hero2{
background: url(../public/assets/img_1.jpg); 
}
.companyabout{
  background-color:rgba(29, 32, 34, 1) !important;
  background: url(../public/assets/Section_bg_2.png); 
  background-size:contain;
}
.companyjoinus{
  background: url(../public/assets//img_1.jpg); 
  min-height: 100vh;
}
.contact{
  background: url(../public/assets/Section_bg_3.png); 
}
.blog{
  background:url(../public/assets/Section_bg.png);  ;
}
 

.hero,
.hero2,
.companyabout,
.companyjoinus,
.contact,
.blog{ 
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
}

@media (max-width: 768px) {
  /* .hero,
.hero2,
.companyabout,
.companyjoinus,
.contact,
.blog{
  background-size: contain;
} */
.companyjoinus{
  min-height: 30vh;
}
}